import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const getDefaultState = () => {
  return {
    id: 0,
    account: "",
    name: "",
    sex:"",
    mobile: "",
    buyerId: 0,
    idcard:"",
    address:"",
    employeeId: null
  }
}

export default new Vuex.Store({
  state: {
    id: 0,
    account: "",
    name: "",
    sex:"",
    mobile: "",
    buyerId: 0,
    idcard:"",
    address:"",
    employeeId: ""
  },
  mutations: {
    updateId(state, id) {
      state.id = id;
    },
    updateUserAccount(state, account) {
      state.account = account;
    },
    updateUserName(state, name) {
      state.name = name;
    },
    updateMobile(state, mobile) {
      state.mobile = mobile;
    },
    updateBuyerid(state, buyerid) {
      state.buyerId = buyerid;
    },
    updateSex(state, sex) {
      state.sex = sex;
    },
    updateIdcard(state,idcard){
      state.idcard = idcard;
    },
    updateAddress(state,address){
      state.address = address;
    },
    updateEmployeeId(state, id) {
      state.employeeId = id;
    },
    RESET_STATE: (state) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    changeName({ commit }, name) {
      commit("updateUserName", name);
    },
    logout({ commit, state }) {
      commit('RESET_STATE')
    },
  },
  strict: process.env.NODE_ENV !== "production",
})
