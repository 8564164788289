import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import nutui from '@nutui/nutui';
import $http from './utils/http'
import 'element-ui/lib/theme-chalk/index.css';
import '@nutui/nutui/dist/nutui.scss';
import VueCookies from 'vue-cookies'
import directives from "./utils/directives";
import VueClipboard from 'vue-clipboard2';

// reset.scss
import './assets/style/global.scss'

Vue.use(ElementUI);
Vue.use(VueCookies);
Vue.use(VueClipboard)
// 京东nutUI 2.3.10 版本
Vue.use(nutui);

Object.keys(directives).forEach(item => {
  // console.log( directives[item])
  Vue.directive(item, directives[item])
})


Vue.config.productionTip = false
Vue.prototype.$http = $http
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
