import Vue from 'vue'
import Router from 'vue-router'
import {Message} from 'element-ui'

let Base64 = require('js-base64').Base64
Vue.use(Router,Message,Base64)


const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: "/",
      redirect: "/Home",
    },
    // 菜单列表
    {
      path: '/Home',
      name: 'Home',
      meta: { title: "首页-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/Home.vue')
    },
    // 菜单列表
    {
      path: '/menu',
      name: 'menu',
      meta: { title: "购物列表-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/Menu.vue')
    },
    // 购物车
    {
      path: '/shopcart',
      name: 'shopcart',
      meta: { title: "购物车列表-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/cartList.vue')
    },
    // 我的
    {
      path: '/mine',
      name: 'mine',
      meta: { title: "用户管理-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/My.vue')
    },
    // 订单核实
    {
      path: '/orderComfirm',
      name: 'orderComfirm',
      meta: { title: "用户订单-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/orderComfirm.vue')
    },
    // 订单列表
    {
      path: '/order',
      name: 'order',
      meta: { title: "用户商城订单-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/order.vue')
    },
    // 订单详情
    {
      path: '/orderDetails',
      name: 'orderDetails',
      meta: { title: "订单详情-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/orderDetail.vue')
    },
    // 用户信息详情
    {
      path: '/person',
      name: 'person',
      meta: { title: "用户信息-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/personel.vue')
    },
    // 登陆
    {
      path: '/Login',
      name: 'Login',
      meta: { title: "登录页-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/Login.vue')
    },
    // 注册
    {
      path: '/Register',
      name: 'Register',
      meta: { title: "注册页-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/Register.vue')
    },
    // 忘记密码
    {
      path: '/Forget',
      name: 'Forget',
      meta: { title: "忘记密码-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/forget.vue')
    },
    // 地址信息
    {
      path: '/address',
      name: 'address',
      meta: { title: "地址信息管理-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/address.vue')
    },
    // 地址信息编辑
    {
      path: '/addressDetail',
      name: 'addressDetail',
      meta: { title: "地址信息详情-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/address-add-or-update.vue')
    },
    // 我的
    {
      path: '/My',
      name: 'My',
      meta: { title: "我的-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/My.vue')
    },
    // 支付状态
    {
      path: '/payStatus',
      name: 'payStatus',
      meta: { title: "支付成功-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/Reservation/payStatus.vue')
    },
    // 公司介绍
    {
      path: '/company',
      name: 'company',
      meta: { title: "墓园介绍-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/zixun/company.vue')
    },
    // 服务项目
    {
      path: '/service',
      name: 'service',
      meta: { title: "服务项目-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/zixun/service.vue')
    },
    // 通知公告
    {
      path: '/announce',
      name: 'announce',
      meta: { title: "通知公告-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/zixun/announce.vue')
    },
    // 通知公告
    {
      path: '/announceDetail',
      name: 'announceDetail',
      meta: { title: "通知公告-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/zixun/announceDetail.vue')
    },
    // 联系我们
    {
      path: '/contactUs',
      name: 'contactUs',
      meta: { title: "联系我们-佛山市灏海餐饮管理有限公司" },
      component: () => import('./views/zixun/contact.vue')
    },
  ],
  // 路由跳转没有回到顶部的问题
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title || "佛山市灏海餐饮管理有限公司"
  const token = Vue.prototype.$cookies.get("shopToken")
  if (token) {
    next()
  } else {
    if (
        to.name === 'order' || to.name === 'orderDetails' ||
        to.name === 'orderComfirm' || to.name === 'person'
    ) {
      Message({
        message: '该页面需要登录后方可进行，请在登录后继续进行访问',
        type: 'error'
      })
      next({ name: 'Login' })
    } else {
      if (to.name === 'payStatus') {
        next({ name: 'payStatus' });
      } else {
        next();
      }

    }

    //   if(to.name !== 'Login' && to.name !== 'Register' && to.name !== 'Forget'){
  //     next({ name: 'Login' })
  //   } else {
  //     next();
  //   }
  }
})


const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve  || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
//路由导航守卫
// router.beforeEach((to, from, next) => {
//     if (to.meta.title) {
//         document.title = to.meta.title
//     }
//     // 如果session和query都没有token，需要去授权
//     let token = sessionStorage.getItem('token') || ''
//     // 通过url的token来注册
//     if (token === '' && !to.query.token) {
//         // 去授权
//         let url =
//             process.env.VUE_APP_AUTHORIZEURL +
//             escape('http://zs.xinglian.info' + to.fullPath)
//         window.location.replace(url)
//     } else {
//         if (token) {
//             // 说明已经有了token，可以直接过去了
//             next()
//         } else {
//             // 说明第一次进来，session中没有token
//             token = to.query.token
//             if (!token || !/\S/.test(token)) {
//                 Message({
//                     message: '用户未注册或未激活（没有token）',
//                     type: 'error'
//                 })
//             } else {
//                 // 第一次拿着token进来要做的事情
//                 let userIdToken = token.slice(
//                     token.indexOf('.') + 1,
//                     token.lastIndexOf('.')
//                 )
//                 //拿到userId和roleList
//                 let data = JSON.parse(Base64.decode(userIdToken))
//                 sessionStorage.setItem('token', token, 30 * 60);
//                 sessionStorage.setItem('systemUserId', data.systemUserId);
//                 next()
//             }
//         }
//     }
// })
export default router

