<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    id: {
      get() {
        return this.$store.state.id;
      },
      set(val) {
        this.$store.commit("updateId", val);
      },
    },
    account: {
      get() {
        return this.$store.state.account;
      },
      set(val) {
        this.$store.commit("updateUserAccount", val);
      },
    },
  },
  created() {
    this.checkFlag();
    // 判断当前的session中，是否有store字段
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      )

      window.addEventListener("beforeunload", () =>{
        sessionStorage.setItem("store", JSON.stringify(this.$store.state))
      })
    } else {
      // 没有store字段的时候，触发请求判断，如果能根据token拿到信息，则继续保存到session里面
      // 否则就清空token数据
      this.getUserInfo();
    }
  },
  methods: {
    // 获取当前用户信息
    getUserInfo() {
      this.$http.httpGet(`/mobile/getUserInfoByToken`).then(data => {
        if (data && data.code === 0) {
          this.loading = false;
          this.id = data.userId;
          this.account = data.userAccount;
          this.$store.commit("updateUserName", data.userName);
          this.$store.commit("updateSex", data.sex);
          this.$store.commit("updateMobile", data.mobile);
          this.$store.commit("updateBuyerid", data.buyerId);
          this.$store.commit("updateIdcard", data.idcard);
          sessionStorage.setItem("store", JSON.stringify(this.$store.state))
        } else {
          this.$router.push({ name: "Home" })
        }
      })
    },
    // 检查是否商城接口信息
    checkFlag() {
      let isShowCarList, isShowValetWorshipList, isShowManageList, isManageYear, isShowAppointList, isShopDelivery, isShowBusinessAppoint;  // 判断当前是否显示商城
      this.$http.httpGet(`/mobile/onlineShop/selectAllFlag`).then(data => {
        if (data && data.code === 0) {
          // 判断当前是否显示商城
          isShowCarList = data.onlineShopFlag.value ? parseInt(data.onlineShopFlag.value) : 0;
          // 检查是否开启代客祭拜
          isShowValetWorshipList = data.valetWorshipFlag.value ? parseInt(data.valetWorshipFlag.value) : 0;
          // 检查是否开启管理费续费
          isShowManageList = data.managementFeeRenewFlag.value ? parseInt(data.managementFeeRenewFlag.value) : 0;
          // 检查是否开启管理费年限选择
          isManageYear = data.editManageYear.value ? parseInt(data.editManageYear.value) : 0;
          // 检查是否开启预约祭拜
          isShowAppointList = data.worshipAppointment.value ? parseInt(data.worshipAppointment.value) : 0;
          // 检查是否开启购物配送
          isShopDelivery = data.onlineShopDeliveryFlag.value ? parseInt(data.onlineShopDeliveryFlag.value) : 0;
          // 检查是否开启业务预约
          isShowBusinessAppoint = data.businessAppointment.value ? parseInt(data.businessAppointment.value) : 0;
          this.$cookies.set("isCarList", isShowCarList)
          this.$cookies.set("isValetWorshipList", isShowValetWorshipList)
          this.$cookies.set("isManageList", isShowManageList)
          this.$cookies.set("isManageYear", isManageYear)
          this.$cookies.set("isAppointList", isShowAppointList)
          this.$cookies.set("isShopDelivery", isShopDelivery)
          this.$cookies.set("isBusinessAppoint", isShowBusinessAppoint)
        } else {
          isShowValetWorshipList = 0; // 检查是否开启代客祭拜
          isShowCarList =  0;  // 判断当前是否显示商城
          isShowManageList = 0; // 检查是否开启管理费续费
          isManageYear = 0; // 检查是否开启管理费年限选择
          isShowAppointList = 0;  // 检查是否开启预约祭拜
          isShopDelivery = 0;  // 检查是否开启购物配送
          isShowBusinessAppoint = 0;
          this.$message.error(data.msg)
          this.$cookies.set("isCarList", isShowCarList)
          this.$cookies.set("isValetWorshipList", isShowValetWorshipList)
          this.$cookies.set("isManageList", isShowManageList)
          this.$cookies.set("isManageYear", isManageYear)
          this.$cookies.set("isAppointList", isShowAppointList)
          this.$cookies.set("isShopDelivery", isShopDelivery)
          this.$cookies.set("isBusinessAppoint", isShowBusinessAppoint)
        }
      })
    },
  }
}
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  background: white;
}

.el-message {
  width: 90% !important;
  min-width: auto !important;
  margin: 5vh auto !important;
}

.el-message-box__wrapper {
  .el-message-box {
    width: 90% !important;
    max-width: 420px !important;
    min-width: auto !important;
    margin: 5vh auto !important;
  }
}
</style>
