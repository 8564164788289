/**
 * @description fitIphoneX 主要是为了适配iphoneX自适配的问题,可以设置padding,maring,bottom
 * @params setValue 需要设置的值  | type 设置的类型，比如说padding
 * @useage  v-fitIphoneX="{ type: 'padding', pxNum: 10 }"
 */
const fitIphoneX = {
  bind(el, binding) {
    let ua = window.navigator.userAgent
    let isIos = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    let designWidth = 375 // 设计稿高度
    let iphoneXNum = (binding.value.pxNum || 30) + 34
    let setValue = (100 / designWidth) * iphoneXNum // 转化成vw
    if (isIos) {
      if (window.screen.height === 812 && window.screen.width === 375) {
        // 在iphonex 中
        switch (binding.value.type) {
          case 'padding':
            el.style.paddingBottom = `${setValue}vw`
            break
          case 'margin':
            el.style.marginBottom = `${setValue}vw`
            break
          default:
            el.style.bottom = `${setValue}vw`
            break
        }
      }
    }
  }
}

export default {
  fitIphoneX
}
